import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { defaultFlags, FlagsProvider } from "./providers/FlagsProvider/FlagsProvider";
import { LicenseInfo } from "@mui/x-license";
import { AuthProvider } from "./providers/AuthProvider/AuthProvider";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { SnackbarCloseButton } from "./components/snackbar/SnackbarCloseButton";
import { SnackbarProvider } from "notistack";
import { configureSentry } from "./sentry";
import * as Sentry from "@sentry/react";
import { ConfirmProvider } from "material-ui-confirm";
import { LocationsProvider } from "./providers/LocationsProvider/LocationsProvider";
import { StyledMaterialDesignContent } from "./components/snackbar/StyledMaterialDesignContent";

/**
 * We're explicitly configuring the license key here as it is the recommended approach by mUI team.
 *
 * The key has no confidential info, does no external calls and is just verified in place.
 *
 * See https://mui.com/x/introduction/licensing/#license-key-security
 */
LicenseInfo.setLicenseKey(
  "177486ea829980a9957d2df1fe8ecbadTz0xMTAzNzYsRT0xNzcyNjY4Nzk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y",
);

configureSentry();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary showDialog={true}>
        <FlagsProvider defaults={defaultFlags}>
          <SnackbarProvider
            action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            maxSnack={2}
            hideIconVariant
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
              warning: StyledMaterialDesignContent,
            }}
          >
            <ConfirmProvider
              defaultOptions={{
                confirmationButtonProps: { autoFocus: true, variant: "contained" },
                buttonOrder: ["confirm", "cancel"],
              }}
            >
              <BrowserRouter>
                <AuthProvider>
                  <LocationsProvider>
                    <App />
                  </LocationsProvider>
                </AuthProvider>
              </BrowserRouter>
            </ConfirmProvider>
          </SnackbarProvider>
        </FlagsProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
