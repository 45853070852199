import React from "react";
import { Box, Divider, Typography, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

const ParcelDetail = ({ label, value }) => (
  <Box className="flex flex-col smMax:w-1/3 w-1/6 smMax:mx-0.5 mx-2">
    <Typography variant="caption" className="text-gray-500 smMax:text-xs">
      {label}
    </Typography>
    <Typography className="smMax:text-sm">{value}</Typography>
  </Box>
);

const ActionButtons = ({ onEdit, onDelete }) => (
  <Box className="flex flex-row w-1/6 items-center justify-end smMax:justify-between">
    <IconButton onClick={onEdit}>
      <Edit />
    </IconButton>
    <IconButton onClick={onDelete}>
      <Delete />
    </IconButton>
  </Box>
);

const OrderPlaceItemList = ({ updateParcelItem, deleteParcelItem }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const parcelItems = values.places || [];

  return (
    <Box className="my-2 w-full">
      {parcelItems.length > 0 &&
        parcelItems.map((parcel, index) => (
          <Box key={index} className="my-2 flex w-full">
            <Box className="flex flex-col w-full">
              <Box className="flex justify-between items-center smMax:items-start">
                <Typography variant="h6" className="smMax:text-md">
                  {t("orderCreation.itemTitle")} {index + 1}
                </Typography>
                <Box className="lg:hidden">
                  <ActionButtons
                    onEdit={() => updateParcelItem(parcel.tracking_code || index)}
                    onDelete={() => deleteParcelItem(index)}
                  />
                </Box>
              </Box>
              <Box className="flex flex-row w-full flex-wrap md:flex-nowrap smMax:items-start smMax:justify-between">
                <ParcelDetail
                  label={t("ordersTableHeaders.orderNumber")}
                  value={parcel.tracking_code}
                />
                <ParcelDetail
                  label={t("orderCreation.parcelDescription")}
                  value={parcel.description}
                />
                <ParcelDetail label={t("orderCreation.parcelLength")} value={parcel.length} />
                <ParcelDetail label={t("orderCreation.parcelWidth")} value={parcel.width} />
                <ParcelDetail label={t("orderCreation.parcelHeight")} value={parcel.height} />
                <ParcelDetail label={t("orderCreation.parcelWeight")} value={parcel.weight} />
                <Box className="hidden lg:flex">
                  <ActionButtons
                    onEdit={() => updateParcelItem(parcel.tracking_code || index)}
                    onDelete={() => deleteParcelItem(index)}
                  />
                </Box>
              </Box>
              <Divider orientation="horizontal" flexItem />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default OrderPlaceItemList;
