import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { RECIPIENT_TYPE } from "../constants";
import { toFixedAmount } from "../toFixedAmount";
import { Box, Divider, Typography } from "@mui/material";
import LoadingBackdrop from "../loadingBackdrop/LoadingBackdrop";
import DetailBlock from "./DetailBlock";
import { PaymentBlockWithChip } from "./PaymentBlockWithChip";

function OrderDetails() {
  const { t } = useTranslation();
  const { order, loading } = useOutletContext();
  const recipientMap = useMemo(
    () => ({
      [RECIPIENT_TYPE.COMPANY]: t("statuses.legalEntity"),
      [RECIPIENT_TYPE.INDIVIDUAL]: t("statuses.individualPerson"),
    }),
    [t],
  );

  const formatRecipientType = useCallback(
    (recipientKey) => recipientMap[recipientKey] || recipientKey,
    [recipientMap],
  );

  const isParcel = order.isParcel
    ? `${t("orderTracking.typeDocuments")}`
    : `${t("orderTracking.typeValue")}`;
  const weight = order.isParcel ? "-" : `${order.weight} ${t("orderTracking.weightValue")}`;
  const cod = order.cod ? order.estimatedCost : "0.00";
  const description = order.description === "" ? "-" : order.description;
  const customTag = order.customTag === "" ? "-" : order.customTag;
  const dimensions = order.isParcel ? "-" : `${order.length} x ${order.width} x ${order.height}`;
  const recipientName =
    order.recipientType === RECIPIENT_TYPE.COMPANY
      ? order.recipientCompanyRepresentative || "-"
      : order.recipientName;
  const recipientType = formatRecipientType(order.recipientType);
  const recipientCompany =
    order.recipientType === RECIPIENT_TYPE.COMPANY ? order.recipientName : "-";
  const recipientGovNumber = order.recipientGovNumber || "-";

  const calculateTotalPayments = () => {
    const deliveryAndInsurance = parseFloat(order.deliveryCost) + parseFloat(order.insurance);
    let totalPayByRecipient = parseFloat(cod);
    let totalPayBySender = 0;

    if (order.codCommissionByRecipient) {
      totalPayByRecipient += parseFloat(order.codCommission);
    } else {
      totalPayBySender += parseFloat(order.codCommission);
    }

    if (order.paymentByRecipient) {
      totalPayByRecipient += deliveryAndInsurance;
    } else {
      totalPayBySender += deliveryAndInsurance;
    }
    totalPayByRecipient = toFixedAmount(totalPayByRecipient);
    totalPayBySender = toFixedAmount(totalPayBySender);

    return { totalPayByRecipient, totalPayBySender };
  };

  const { totalPayByRecipient, totalPayBySender } = calculateTotalPayments();

  return (
    <>
      <LoadingBackdrop isVisible={loading} />
      <Box className={"shadow-lg pb-2"}>
        <Box className={"flex items-center justify-start mx-4"}>
          <Typography className={"p-2 font-bold smMax:pb-0"}>
            {t("orderTracking.recipientPart")}
          </Typography>
        </Box>
        <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
        <Box className={"flex flex-col mx-4 p-1.5"}>
          <Box className={"flex items-center justify-stretch w-full smMax:flex-wrap"}>
            <DetailBlock
              text={recipientName}
              title={t("orderTracking.recipientName")}
              className={"my-2 md:w-1/3 w-full"}
            />
            <DetailBlock
              text={order.recipientPhone}
              title={t("orderTracking.recipientPhone")}
              className={"my-2 md:w-1/3 w-1/2"}
            />
            <DetailBlock
              text={recipientType}
              title={t("orderTracking.recipientType")}
              className={"my-2 md:w-1/3 w-1/2"}
            />
          </Box>
          <Box
            className={`${order.recipientType === RECIPIENT_TYPE.INDIVIDUAL ? "hidden" : "flex items-center justify-stretch w-full flex-nowrap"}`}
          >
            <DetailBlock
              text={recipientCompany}
              title={t("orderTracking.company")}
              className={"my-2 w-1/2 md:w-1/3"}
            />
            <DetailBlock
              text={recipientGovNumber}
              title={t("orderTracking.companyID")}
              className={"my-2 w-1/2 md:w-1/3"}
            />
            <Box className={"my-2 w-1/3 smMax:hidden"}></Box>
          </Box>
        </Box>
        <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
        <Box className={"flex items-center justify-start mx-4"}>
          <Typography className={"p-2 font-bold smMax:pb-0"}>
            {t("orderTracking.paymentPart")}
          </Typography>
        </Box>
        <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
        <Box className={"flex flex-col mx-4 p-1.5"}>
          <Box className={"flex items-center justify-stretch w-full smMax:flex-wrap"}>
            <Box className={"flex items-center w-full sm:w-1/2"}>
              <Typography variant={"subtitle1"} className={"text-gray-500 py-2"}>
                {t("orderTracking.paidByRecipient")}:
              </Typography>
              <Typography className={"p-2 font-bold"}>
                {totalPayByRecipient} {t("orderTracking.gel")}
              </Typography>
            </Box>
            <Box className={"flex items-center w-full sm:w-1/2"}>
              <Typography variant={"subtitle1"} className={"text-gray-500 py-2"}>
                {t("orderTracking.paidBySender")}:
              </Typography>
              <Typography className={"p-2 font-bold"}>
                {totalPayBySender} {t("orderTracking.gel")}
              </Typography>
            </Box>
          </Box>
          <Box
            className={
              "flex items-center justify-stretch w-full smMax:flex-col md:flex-col xl:flex-row smMax:items-start"
            }
          >
            <PaymentBlockWithChip
              title={t("orderTracking.deliveryCost")}
              label={
                order.paymentByRecipient
                  ? t("orderCreation.payRecipient")
                  : t("orderCreation.paySender")
              }
              type={order.deliveryCost}
            />
            <PaymentBlockWithChip
              title={t("orderTracking.insurance")}
              label={
                order.paymentByRecipient
                  ? t("orderCreation.payRecipient")
                  : t("orderCreation.paySender")
              }
              type={order.insurance}
            />
          </Box>
          <Box
            className={
              "flex items-center justify-stretch w-full smMax:flex-col md:flex-col xl:flex-row smMax:items-start md:items-start"
            }
          >
            <PaymentBlockWithChip
              title={t("ordersTableHeaders.cod")}
              label={t("orderCreation.payRecipient")}
              type={cod}
            />
            <PaymentBlockWithChip
              title={t("orderTracking.codComition")}
              label={
                order.paymentByRecipient
                  ? t("orderCreation.payRecipient")
                  : t("orderCreation.paySender")
              }
              type={order.codCommission}
            />
          </Box>
        </Box>
        {order.withPlaces && (
          <>
            <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
            <Box className={"flex items-center justify-start mx-4"}>
              <Typography className={"p-2 font-bold smMax:pb-0"}>
                {t("orderCreation.stepParcelWithAdditionalPlaces")}
              </Typography>
            </Box>
            <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
            <Box className={"flex flex-col w-full flex-nowrap smMax:flex-wrap"}>
              <Box
                className={"flex items-center justify-stretch w-full flex-nowrap smMax:flex-wrap"}
              >
                <Box className={"flex items-center w-full flex-col"}>
                  {order.places.map((place, index) => {
                    return (
                      <>
                        <Box
                          className={"flex items-center w-full flex-nowrap smMax:flex-wrap "}
                          key={`k-${index}`}
                        >
                          <Box className={"flex items-center w-1/6 smMax:w-full mx-4"}>
                            <Typography className={"p-2 font-bold"}>
                              {t("orderCreation.itemTitle")} {index + 1}
                            </Typography>
                          </Box>
                          <Divider
                            orientation={"vertical"}
                            flexItem
                            className={"smMax:hidden mr-2"}
                          />
                          <Box className={"flex items-center w-1/3 smMax:w-full smMax:mx-6"}>
                            <Typography variant={"subtitle1"} className={"text-gray-500 py-2"}>
                              {t("ordersTableHeaders.orderNumber")}:
                            </Typography>
                            <Typography className={"p-2 font-bold"}>
                              {place.tracking_code}
                            </Typography>
                          </Box>
                          <Divider
                            orientation={"vertical"}
                            flexItem
                            className={"smMax:hidden mr-2"}
                          />
                          <Box className={"flex items-center w-2/3 smMax:w-full smMax:mx-6"}>
                            <Typography variant={"subtitle1"} className={"text-gray-500 py-2"}>
                              {t("placeholders.description")}:
                            </Typography>
                            <Typography className={"p-2 font-bold"}>
                              {place.description}
                            </Typography>
                          </Box>
                          <Divider
                            orientation={"vertical"}
                            flexItem
                            className={"smMax:hidden mr-2"}
                          />
                          <Box
                            className={
                              "flex items-center w-1/6 smMax:w-1/3 flex-nowrap smMax:flex-wrap smMax:mx-6"
                            }
                          >
                            <Typography variant={"subtitle1"} className={"text-gray-500 py-2"}>
                              {t("fieldNames.length")}:
                            </Typography>
                            <Typography className={"p-2 font-bold"}>{place.length}</Typography>
                          </Box>
                          <Divider
                            orientation={"vertical"}
                            flexItem
                            className={"smMax:hidden mr-2"}
                          />
                          <Box
                            className={
                              "flex items-center w-1/6 smMax:w-1/3 flex-nowrap smMax:flex-wrap smMax:mx-6"
                            }
                          >
                            <Typography variant={"subtitle1"} className={"text-gray-500 py-2"}>
                              {t("fieldNames.width")}:
                            </Typography>
                            <Typography className={"p-2 font-bold"}>{place.width}</Typography>
                          </Box>
                          <Divider
                            orientation={"vertical"}
                            flexItem
                            className={"smMax:hidden mr-2"}
                          />
                          <Box
                            className={
                              "flex items-center w-1/6 smMax:w-1/3 flex-nowrap smMax:flex-wrap smMax:mx-6"
                            }
                          >
                            <Typography variant={"subtitle1"} className={"text-gray-500 py-2"}>
                              {t("fieldNames.height")}:
                            </Typography>
                            <Typography className={"p-2 font-bold"}>{place.height}</Typography>
                          </Box>
                          <Divider
                            orientation={"vertical"}
                            flexItem
                            className={"smMax:hidden mr-2"}
                          />
                          <Box
                            className={
                              "flex items-center w-1/6 smMax:w-1/3 flex-nowrap smMax:flex-wrap smMax:mx-6"
                            }
                          >
                            <Typography variant={"subtitle1"} className={"text-gray-500 py-2"}>
                              {t("fieldNames.weight")}:
                            </Typography>
                            <Typography className={"p-2 font-bold"}>{place.weight}</Typography>
                          </Box>
                        </Box>
                        <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
                      </>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </>
        )}
        <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
        <Box className={"flex items-center justify-start mx-4"}>
          <Typography className={"p-2 font-bold smMax:pb-0"}>
            {t("orderTracking.departurePart")}
          </Typography>
        </Box>
        <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
        <Box className={"flex flex-col mx-4 p-1.5"}>
          <Box className={"flex items-start justify-stretch w-full smMax:flex-wrap"}>
            <Box className={"my-2 w-1/3 smMax:w-full"}>
              <Typography variant="caption" className={"text-gray-500"}>
                {t("orderTracking.departureAddressPart")}
              </Typography>
              <Typography>
                {order.pickupCity}, <br className={"smMax:hidden"} />
                {order.pickupAddress}
              </Typography>
            </Box>
            <Box className={"my-2 w-1/3 md:hidden smMax:w-full"}>
              <Typography variant="caption" className={"text-gray-500"}>
                {t("orderTracking.deliveryAddressPart")}
              </Typography>
              <Typography>
                {order.deliveryCity}, <br className={"smMax:hidden"} />
                {order.deliveryAddress}
              </Typography>
            </Box>
            <DetailBlock
              text={`${order.estimatedCost} ${t("orderTracking.gel")}`}
              title={t("orderTracking.assessedValue")}
              className={"my-2 w-1/3 smMax:w-1/2"}
            />
            <DetailBlock
              text={`${cod} ${t("orderTracking.gel")}`}
              title={t("ordersTableHeaders.cod")}
              className={"my-2 w-1/3 smMax:w-1/2"}
            />
          </Box>
          <Box className={"flex items-start justify-stretch w-full smMax:flex-wrap"}>
            <Box className={"my-2 w-1/3 smMax:hidden"}>
              <Typography variant="caption" className={"text-gray-500"}>
                {t("orderTracking.deliveryAddressPart")}
              </Typography>
              <Typography>
                {order.deliveryCity},<br />
                {order.deliveryAddress}
              </Typography>
            </Box>
            <DetailBlock
              text={weight}
              title={t("ordersTableHeaders.weight")}
              className={`my-2 w-1/3 smMax:w-1/2 ${order.isParcel ? "smMax:hidden" : ""}`}
            />
            <DetailBlock
              text={dimensions}
              title={t("orderTracking.dimensions")}
              className={`my-2 w-1/3 smMax:w-1/2 ${order.isParcel ? "smMax:hidden" : ""}`}
            />
          </Box>
          <Box className={"flex items-start justify-stretch w-full smMax:flex-wrap"}>
            <Box
              className={`my-2 w-1/3 smMax:w-full ${order.description === "" ? "smMax:hidden" : ""}`}
            >
              <Typography variant="caption" className={"text-gray-500"}>
                {t("ordersTableHeaders.description")}
              </Typography>
              <Typography variant="body2" className={"whitespace-normal truncate"}>
                {description}
              </Typography>
            </Box>
            <Box
              className={`my-2 w-1/3 smMax:w-full ${order.customTag === "" ? "smMax:hidden" : ""}`}
            >
              <Typography variant="caption" className={"text-gray-500"}>
                {t("orderCreation.parcelAttribute")}
              </Typography>
              <Typography variant="body2" className={"whitespace-normal truncate"}>
                {customTag}
              </Typography>
            </Box>
            <DetailBlock
              text={isParcel}
              title={t("orderTracking.type")}
              className={"my-2 w-1/3 smMax:w-1/2"}
            />
          </Box>
        </Box>
        <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
        <Box className={"flex items-center justify-start mx-4"}>
          <Typography className={"p-2 font-bold smMax:py-0"}>
            {t("orderTracking.sender")}
          </Typography>
        </Box>
        <Divider orientation={"horizontal"} flexItem className={"smMax:hidden"} />
        <Box className={"flex flex-col mx-4 p-1.5"}>
          <Box className={"flex items-start justify-stretch w-full smMax:flex-wrap"}>
            <DetailBlock
              text={order.senderEmail}
              title={t("accountPage.title")}
              className={"my-2 md:w-1/3 w-full"}
            />
            <Box className={"my-2 w-1/3 smMax:hidden"}>
              <Typography variant="caption" className={"text-gray-500"}>
                {t("orderTracking.phoneNumber")}
              </Typography>
              <Typography>{order.senderPhone}</Typography>
            </Box>
            <Box className={"my-2 w-1/3 smMax:hidden"}></Box>
          </Box>
          <Box className={"flex items-center justify-stretch w-full smMax:flex-wrap"}>
            <DetailBlock
              text={order.senderCompanyName}
              title={t("orderTracking.company")}
              className={"my-2 md:w-1/3 w-full"}
            />
            <Box className={"my-2 smMax:w-1/2 md:hidden"}>
              <Typography variant="caption" className={"text-gray-500"}>
                {t("orderTracking.phoneNumber")}
              </Typography>
              <Typography>{order.senderPhone}</Typography>
            </Box>
            <DetailBlock
              text={order.senderGovNumber}
              title={t("orderTracking.companyID")}
              className={"my-2 md:w-1/3 w-1/2"}
            />
            <Box className={"my-2 w-1/3 smMax:hidden"}></Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default OrderDetails;
