import { useCallback } from "react";
import { Order, OrdersListQueryParams } from "../api/orderTypes";
import { useLocationsContext } from "../providers/LocationsProvider/LocationsProvider";
import AuthenticationError from "../errors/AuthenticationError";
import { signOutUser } from "../firebase";

/**
 * Fetches orders using a given handler and converts orders to their enhanced representation.
 * @param {function(OrdersListQueryParams): Promise<OrderListItem[]>} fetchOrdersHandler
 * @returns {{fetchData: ((function(*): Promise<Order[]>)|*)}}
 */
export function useFetchOrders(fetchOrdersHandler) {
  const { getCityById } = useLocationsContext();

  const fetchData = useCallback(
    async ({ startDate, endDate }) => {
      try {
        const queryParams = new OrdersListQueryParams({
          date_from: startDate,
          date_to: endDate,
        });
        const ordersData = await fetchOrdersHandler(queryParams);
        return ordersData.map((order) => {
          const departureCity = getCityById(order.departureCityId);
          const departureCityName = departureCity ? departureCity.city : "";
          const destinationCity = getCityById(order.destinationCityId);
          const destinationCityName = destinationCity ? destinationCity.city : "";
          const isPaymentByRecipient = order.paymentByRecipient;
          const isCodByRecipient = order.cod ? order.codByRecipient : false;
          const recipientName =
            order.recipient.alias !== "" ? order.recipient.alias : order.recipient.name;
          const deliveryAddress =
            order.recipient.address !== ""
              ? order.recipient.address
              : `${order.recipient.post_office.name}, ${order.recipient.post_office.address}`;
          const codCost = order.cod ? order.estimatedCost : 0;
          const departureAddress =
            order.sender.address !== ""
              ? order.sender.address
              : `${order.sender.post_office.name}, ${order.sender.post_office.address}`;
          return new Order({
            id: order.trackingCode,
            trackingCode: order.trackingCode,
            internationalTrackingNumber: order.internationalTrackingNumber,
            description: order.description,
            shipmentStatus: order.shipmentStatus,
            orderDate: order.orderDate,
            deliveryDate: order.deliveryDate,
            recipientName: recipientName,
            recipientPhone: order.recipient.phone,
            deliveryCity: destinationCityName,
            deliveryAddress: deliveryAddress,
            departureCity: departureCityName,
            departureAddress: departureAddress,
            weight: order.weight,
            deliveryCost: order.deliveryCost,
            paymentByRecipient: isPaymentByRecipient,
            estimatedCost: order.estimatedCost,
            cod: codCost,
            codByRecipient: isCodByRecipient,
            addServicesCost: order.insuranceCost,
            registerNumber: order.registerNumber,
            cityName: destinationCityName,
            customTag: order.customTag,
            withPlaces: order.withPlaces,
            places: order.places,
          });
        });
      } catch (error) {
        if (error instanceof AuthenticationError) {
          await signOutUser();
        }
        console.error("Error fetching orders:", error);
        throw error;
      }
    },
    [fetchOrdersHandler, getCityById],
  );
  return { fetchData };
}
