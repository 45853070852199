import React, { useState } from "react";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import { mergician } from "mergician";
import CalendarPicker from "../CalendarPicker";
import { useItemsPerScreenSize } from "../../hooks/useItemsPerScreenSize";
import { ActionsToolBar } from "./ActionToolBar";
import { useLocaleText } from "./useLocaleText";

/**
 * Rows per screen mapping.
 * @type {Object}
 * @property {number} sm - Number of rows for small screens(599px and less)).
 * @property {number} lg - Number of rows for large screens (600px - 1535px).
 * @property {number} xl - Number of rows for extra-large screens (1536px- 2559px).
 * @property {number} xxl - Number of rows for extra-extra-large screens (2560px and bigger).
 * @property {number} default - Default number of rows .
 */
const rowsPerScreen = {
  sm: 5,
  lg: 15,
  xl: 25,
  xxl: 50,
  default: 50,
};
export const defaultPageSizeOptions = [5, 15, 25, 50, 100, 200];

export const defaultInitialState = {
  pagination: {
    paginationModel: {
      pageSize: rowsPerScreen.default,
    },
  },
};

/**
 * Returns a deep copy of the given initial state merged with the default initial state config.
 */
export const withDefaults = (initialState) => {
  return mergician(defaultInitialState, initialState);
};

/**
 * Renders a DataGridPremium component with various customization options such as editing,
 * selection, and toolbars for actions and filters. Supports dynamic control for rendering
 * different elements based on the provided props.
 *
 * @param {Array} rows - The rows to be displayed in the data grid.
 * @param {Array} columns - Column configurations for the data grid.
 * @param {Object} rowModesModel - The model to control the edit mode of each row.
 * @param {Function} onRowModesModelChange - Callback fired when the row edit modes change.
 * @param {string} className - Additional CSS class for the data grid component.
 * @param {Function} onRowEditStop - Callback fired when the row edit is committed or cancelled.
 * @param {Function} processRowUpdate - Callback fired to process the row updates.
 * @param {boolean} [checkboxSelection=true] - Enables checkbox selection mode.
 * @param {Object} [slotProps] - Additional properties to pass to the slot components.
 * @param {string} emptyTableText - Text to display when there are no rows.
 * @param {Array|number} selectionModel - The selection model of the grid.
 * @param {string} title - Title displayed above the data grid.
 * @param {Function} onRowSelectionModelChange - Callback fired when the selection model changes.
 * @param {Array} [pageSizeOptions=defaultPageSizeOptions] - Array of page sizes for pagination.
 * @param {Object} [initialState=defaultInitialState] - The initial state of the data grid.
 * @param {boolean} [showCalendar=false] - Flag to show or hide the calendar picker.
 * @param {boolean} [showDeleteIcon=false] - Flag to show or hide the delete icon.
 * @param {boolean} [showPrintIcon=false] - Flag to show or hide the print icon.
 * @param {boolean} [showArchiveIcon=false] - Flag to show or hide the archive icon.
 * @param {boolean} [showUnarchiveIcon=false] - Flag to show or hide the unarchive icon.
 * @param {boolean} [showOtherTablesHeader=false] - Flag to show or hide other tables' headers.
 * @param {Function} [onDelete=() => {}] - Callback for delete action.
 * @param {Function} [onPrint=() => {}] - Callback for print action.
 * @param {Function} [onArchive=() => {}] - Callback for archive action.
 * @param {Function} [onUnarchive=() => {}] - Callback for unarchive action.
 * @param {Function} [onRefresh=() => {}] - Callback for refresh action.
 * @param {boolean} [disableIcons=false] - Disables the action icons.
 * @param {Date[]} selectedDates - Selected dates for the calendar picker.
 * @param {Function} onDateChange - Callback fired when selected dates change.
 * @param {boolean} [loading=true] - Controls the loading state of the grid.
 * @param {boolean} [isRefreshBtnVisible=true] - Controls visibility of the refresh button.
 * @param {JSX.Element} headerComponent - Component to render as the header of other tables.
 * @param {...Object} props - Additional props to pass to the DataGridPro component.
 * @returns {JSX.Element} The configured DataGridPro component.
 */
function DataGridTable({
  rows,
  columns,
  rowModesModel,
  onRowModesModelChange,
  className,
  onRowEditStop,
  processRowUpdate,
  checkboxSelection = true,
  slotProps = {
    toolbar: {
      showQuickFilter: true,
    },
  },
  emptyTableText,
  selectionModel,
  title,
  onRowSelectionModelChange,
  pageSizeOptions = defaultPageSizeOptions,
  initialState = defaultInitialState,
  showCalendar = false,
  showDeleteIcon = false,
  showPrintIcon = false,
  showArchiveIcon = false,
  showUnarchiveIcon = false,
  showOtherTablesHeader = false,
  onDelete = () => {},
  onPrint = () => {},
  onArchive = () => {},
  onUnarchive = () => {},
  onRefresh = () => {},
  disableIcons = false,
  selectedDates,
  onDateChange,
  loading = true,
  isRefreshBtnVisible = true,
  headerComponent,
  ...props
}) {
  const { t } = useTranslation();
  const [showHiddenButtons, setShowHiddenButtons] = useState(false);

  const handleDateChange = (dates) => {
    onDateChange(dates);
  };

  function ExportDataGridToolbar() {
    return (
      <>
        {showCalendar ? (
          <>
            <Box className={"flex flex-col md:flex-row md:justify-between"}>
              <Box className={"md:w-3/4"}> {title}</Box>
              <Box className={"flex"}>
                <ButtonComponent text={<RefreshIcon />} onClick={onRefresh} />
                <GridToolbarQuickFilter
                  className={"flex mx-4 my-3 w-full justify-self-end self-end"}
                />
              </Box>
            </Box>
            <GridToolbarContainer className={"px-3 flex justify-between"}>
              <ActionsToolBar
                showPrintIcon={showPrintIcon}
                onPrint={onPrint}
                disableIcons={disableIcons}
                showArchiveIcon={showArchiveIcon}
                onArchive={onArchive}
                showUnarchiveIcon={showUnarchiveIcon}
                onUnarchive={onUnarchive}
                showDeleteIcon={showDeleteIcon}
                onDelete={onDelete}
                showHiddenButtons={showHiddenButtons}
                setShowHiddenButtons={setShowHiddenButtons}
              />
              <CalendarPicker selectedDates={selectedDates} onDateChange={handleDateChange} />
            </GridToolbarContainer>
          </>
        ) : (
          <>
            {showOtherTablesHeader && <Box>{headerComponent}</Box>}
            <GridToolbarContainer className={"px-3 flex justify-between"}>
              <ActionsToolBar
                showPrintIcon={showPrintIcon}
                onPrint={onPrint}
                disableIcons={disableIcons}
                showArchiveIcon={showArchiveIcon}
                onArchive={onArchive}
                showUnarchiveIcon={showUnarchiveIcon}
                onUnarchive={onUnarchive}
                showDeleteIcon={showDeleteIcon}
                showHiddenButtons={showHiddenButtons}
                setShowHiddenButtons={setShowHiddenButtons}
              />
              <Box className={"flex"}>
                <GridToolbarQuickFilter className={"flex mx-4 my-3 self-end"} />
                {isRefreshBtnVisible && (
                  <ButtonComponent text={<RefreshIcon />} onClick={onRefresh} />
                )}
              </Box>
            </GridToolbarContainer>
          </>
        )}
      </>
    );
  }

  const rowsNumber = useItemsPerScreenSize({ items: rowsPerScreen });
  const localeText = useLocaleText(t);

  return (
    <DataGridPremium
      key={rowsNumber}
      rows={rows}
      columns={columns}
      selectionModel={selectionModel}
      onSelectionChange
      onRowSelectionModelChange={onRowSelectionModelChange}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={onRowModesModelChange}
      onRowEditStop={onRowEditStop}
      processRowUpdate={processRowUpdate}
      className={className}
      slotProps={{
        ...slotProps,
        filterPanel: {
          sx: {
            maxWidth: "calc(100vw - 2rem)",
          },
        },
      }}
      pagination={true}
      initialState={{
        ...initialState,
        pagination: {
          paginationModel: {
            pageSize: rowsNumber,
          },
        },
      }}
      pageSize={rowsNumber}
      pageSizeOptions={pageSizeOptions}
      slots={{
        noRowsOverlay: () => {
          return emptyTableText;
        },
        toolbar: ExportDataGridToolbar,
      }}
      localeText={localeText}
      checkboxSelection={checkboxSelection}
      disableRowSelectionOnClick={true}
      autoHeight={true}
      loading={loading}
      {...props}
    />
  );
}

export default DataGridTable;
