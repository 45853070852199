import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DataGridTable from "../dataGrid/DataGridTable";
import { dateColumnDef } from "../dateTimeColumnDef";
import { OrderStatus, useOrders } from "../../hooks/useOrders";
import {
  cod,
  codByRecipient,
  customTag,
  deliveryAddress,
  deliveryCity,
  departureAddress,
  departureCity,
  description,
  estimatedCost,
  internationalTrackingNumber,
  orderNumber,
  otherCosts,
  printIcon,
  recipient,
  recipientPhone,
  registerNumber,
  shipmentStatus,
  weight,
  is_return,
  withPlaces,
  places,
} from "../reusableOrdersColumns";
import { bulkPrintStickers, handlePrintOrderSticker } from "../handlePrint";
import useStatusTranslations from "../../hooks/useStatusTranslations";
import { useSnackbar } from "notistack";
import { defaultFiveDaysAgo, today } from "../dates";
import { useNavigate } from "react-router-dom";
import showApiErrorNotification from "../showApiErrorNotification";
import { validateOrders } from "../validateOrders";
import { PageTitle } from "../pageTitle/PageTitle";
import { DataGridWrapper } from "../dataGrid/DataGridWrapper";

function MyOrders() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedDates, setSelectedDates] = useState([defaultFiveDaysAgo, today]);

  const {
    orders,
    loading: loadingOrders,
    archiveOrders,
    fetchOrders,
  } = useOrders(OrderStatus.ACTIVE, {
    startDate: selectedDates[0],
    endDate: selectedDates[1],
  });
  const { formatOrderStatus } = useStatusTranslations();
  const [selectedOrderTrackingCodes, setSelectedOrderTrackingCodes] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const validatedOrders = useMemo(() => validateOrders(orders, t), [orders, t]);

  const columns = useMemo(
    () => [
      orderNumber(t),
      printIcon(t, 80, (trackingCode) => handlePrintOrderSticker(trackingCode)),
      recipient(t),
      recipientPhone(t),
      deliveryCity(t),
      deliveryAddress(t),
      shipmentStatus(t, formatOrderStatus),
      {
        field: "orderDate",
        headerName: `${t("ordersTableHeaders.sendDate")}`,
        width: 180,
        align: "left",
        headerAlign: "left",
        ...dateColumnDef,
      },
      {
        field: "deliveryDate",
        headerName: `${t("ordersTableHeaders.estimatedTime")}`,
        width: 180,
        align: "left",
        headerAlign: "left",
        ...dateColumnDef,
      },
      customTag(t),
      internationalTrackingNumber(t),
      description(t),
      weight(t),
      estimatedCost(t),
      cod(t),
      codByRecipient(t),
      otherCosts(t),
      departureCity(t),
      departureAddress(t),
      registerNumber(t, 150, false),
      is_return(t),
      withPlaces(t),
      places(t),
    ],
    [t, formatOrderStatus],
  );

  const goToArchive = () => {
    navigate("/orders/archive");
  };

  const handlerBulkPrintStickersHandler = async () => {
    try {
      await bulkPrintStickers(selectedOrderTrackingCodes);
    } catch (error) {
      showApiErrorNotification(t("alertsMessages.failedPrintStickers"), error, enqueueSnackbar);
      console.error(`Error printing stickers: ${selectedOrderTrackingCodes}`, error);
    }
  };

  const handlerArchiveOrders = async (selectedTrackingCodes) => {
    try {
      const archivedOrders = await archiveOrders(selectedTrackingCodes);
      if (archivedOrders) {
        enqueueSnackbar(
          selectedTrackingCodes.length === 1
            ? t("alertsMessages.orderArchived")
            : t("alertsMessages.ordersArchived"),
          {
            variant: "success",
            action: <Button onClick={goToArchive}>{t("alertsMessages.goToArchive")}</Button>,
          },
        );
        fetchOrders({ startDate: selectedDates[0], endDate: selectedDates[1] });
      } else {
        enqueueSnackbar(t("alertsMessages.failedArchiveOrder"), {
          variant: "error",
        });
        console.error(`Error archive orders: ${selectedTrackingCodes}`);
      }
    } catch (error) {
      showApiErrorNotification(t("alertsMessages.failedArchiveOrder"), error, enqueueSnackbar);
      console.error(`Error archive orders: ${selectedTrackingCodes}`, error);
    }
  };

  const handleDateChange = useCallback(
    (dates) => {
      setSelectedDates(dates);
    },
    [setSelectedDates],
  );

  return (
    <DataGridWrapper>
      <DataGridTable
        title={<PageTitle title={t("pagesTitles.myOrdersTitle")} />}
        showCalendar={true}
        selectedDates={selectedDates}
        onDateChange={handleDateChange}
        rows={validatedOrders}
        autoHeight={false}
        showPrintIcon={true}
        showArchiveIcon={true}
        rowSelectionModel={selectedOrderTrackingCodes}
        onRowSelectionModelChange={setSelectedOrderTrackingCodes}
        onPrint={handlerBulkPrintStickersHandler}
        onArchive={() => handlerArchiveOrders(selectedOrderTrackingCodes)}
        onRefresh={() => fetchOrders({ startDate: selectedDates[0], endDate: selectedDates[1] })}
        disableIcons={selectedOrderTrackingCodes.length === 0}
        columns={columns}
        loading={loadingOrders}
        emptyTableText={
          <Typography className={"text-center pt-8"}>
            {t("ordersTableHeaders.noOrders")}
          </Typography>
        }
      />
    </DataGridWrapper>
  );
}

export default MyOrders;
