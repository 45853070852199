/**
 * @typedef {object} PostOfficeMapping post office mapping
 * @property {string} guid The GUID of the post office
 * @property {string} name The name of the post office
 */

/**
 * @typedef {object} OrderRecipientMapping Recipient object mapping
 * @property {string} guid - The id of the order.
 * @property {string} gov_number - The tracking code of the order.
 * @property {string} name - The description of the order.
 * @property {string} phone - The shipment status of the order.
 * @property {string} address - The date of sending the order.
 * @property {PostOfficeMapping} post_office - The date of delivery the order.
 * @property {string} alias - The name of the recipient.
 */

/**
 * @typedef {object} OrderSenderMapping Recipient object mapping
 * @property {string} guid - The id of the order.
 * @property {string} gov_number - The tracking code of the order.
 * @property {string} name - The description of the order.
 * @property {string} address - The date of sending the order.
 * @property {PostOfficeMapping} post_office - The date of delivery the order.
 */

/**
 * @typedef {object} OrderPlace A sub-parcel (place) in an order
 *
 * @property {number} length - The length of the place
 * @property {number} width - The width of the place
 * @property {number} height - The height of the place
 * @property {number} weight - The weight of the place
 * @property {string} description - The description of the place
 * @property {string} tracking_code - The tracking code of the place
 */

export class OrderPlace {
  /**
   * Creates an order place.
   * @param {OrderPlace} orderPlace order place information
   */
  constructor({ length, width, height, weight, description, tracking_code }) {
    this.length = length;
    this.width = width;
    this.height = height;
    this.weight = weight;
    this.description = description;
    this.tracking_code = tracking_code;
  }
}

/**
 * @typedef {object} OrderListItemMapping A single OrderListItem object mapping
 *
 * @property {string} tracking_code The tracking code of the order
 * @property {string} international_tracking_number - The international tracking number.
 * @property {string} shipment_status The shipment status of the order
 * @property {OrderSenderMapping} sender The sender's info
 * @property {OrderRecipientMapping} recipient The recipient's info
 * @property {string} departure_city_id the ID of the departure city
 * @property {string} destination_city_id the ID of the destination city
 * @property {boolean} cod determines if COD is there
 * @property {boolean} cod_by_recipient  COD is by the recipient
 * @property {string} estimated_cost - The estimated cost of the order
 * @property {string} description the description of the order
 * @property {string} order_date The date of sending the order
 * @property {string} weight The weight of the order
 * @property {boolean} payment_by_recipient Payment is by the recipient
 * @property {string} insurance_cost The insurance cost of the order
 * @property {string} delivery_cost The delivery cost of the order
 * @property {string} register_number The number of the register to which the order belongs
 * @property {string} delivery_date The delivery date of the order
 * @property {string} client_custom_attribute The custom tag of the order
 * @property {boolean} order.with_places -The identification of additional places.
 * @property {Array} order.places - The array of parcels with additional places.
 */

export class OrderListItem {
  /**
   * Creates an OrderList item instance.
   *
   * @param {OrderListItemMapping} order - The order data to be created.
   * @param {string} tracking_code The tracking code of the order
   * @param {string} international_tracking_number - The international tracking number.
   * @param {string} shipment_status The shipment status of the order
   * @param {OrderSenderMapping} sender The sender's info
   * @param {OrderRecipientMapping} recipient The recipient's info
   * @param {string} departure_city_id the ID of the departure city
   * @param {string} destination_city_id the ID of the destination city
   * @param {boolean} cod determines if COD is there
   * @param {boolean} cod_by_recipient  COD is by the recipient
   * @param {string} estimated_cost - The estimated cost of the order
   * @param {string} description the description of the order
   * @param {string} order_date The date of sending the order
   * @param {string} weight The weight of the order
   * @param {boolean} payment_by_recipient Payment is by the recipient
   * @param {string} insurance_cost The insurance cost of the order
   * @param {string} delivery_cost The delivery cost of the order
   * @param {string} register_number The number of the register to which the order belongs
   * @param {string} delivery_date The delivery date of the order
   * @param {string} client_custom_attribute The custom tag of the order
   * @param {string} recipient_organizations_representative - Recipient organizations representative
   * @param {boolean} order.with_places -The identification of additional places.
   * @param {Array} order.places - The array of parcels with additional places.
   */
  constructor({
    tracking_code,
    international_tracking_number,
    shipment_status,
    sender,
    recipient,
    departure_city_id,
    destination_city_id,
    cod,
    cod_by_recipient,
    estimated_cost,
    description,
    order_date,
    weight,
    payment_by_recipient,
    insurance_cost,
    delivery_cost,
    register_number,
    delivery_date,
    client_custom_attribute,
    recipient_organizations_representative,
    with_places,
    places,
  }) {
    this.trackingCode = tracking_code;
    this.internationalTrackingNumber = international_tracking_number;
    this.shipmentStatus = shipment_status;
    this.sender = sender;
    this.recipient = recipient;
    this.departureCityId = departure_city_id;
    this.destinationCityId = destination_city_id;
    this.cod = cod;
    this.codByRecipient = cod_by_recipient;
    this.estimatedCost = estimated_cost;
    this.description = description;
    this.orderDate = order_date;
    this.weight = weight;
    this.paymentByRecipient = payment_by_recipient;
    this.insuranceCost = insurance_cost;
    this.deliveryCost = delivery_cost;
    this.registerNumber = register_number;
    this.deliveryDate = delivery_date;
    this.customTag = client_custom_attribute;
    this.recipientOrganizationsRepresentative = recipient_organizations_representative;
    this.withPlaces = with_places;
    this.places = places;
  }
}

//TODO:2023-11-26:yuri-sergiichuk: all the order-related mappings are so messed up here. we should clear them up
//  and make sure they are synced with the API definitions. This mess makes it really hard to
//  do any actions here.
export class Order {
  /**
   * Create an Order instance.
   *
   * @param {object} order - The order data to be created.
   * @param {string} order.id - The id of the order.
   * @param {string} order.trackingCode - The tracking code of the order.
   * @param {string} order.internationalTrackingNumber - The international tracking number.
   * @param {string} order.description - The description of the order.
   * @param {string} order.shipmentStatus - The shipment status of the order.
   * @param {string} order.orderDate - The date of sending the order.
   * @param {string} order.deliveryDate - The date of delivery the order.
   * @param {string} order.recipientName - The name of the recipient.
   * @param {string} order.recipientPhone - The phone number of the recipient.
   * @param {string} order.deliveryCity - The delivery city of the recipient.
   * @param {string} order.deliveryAddress - The delivery address of the recipient.
   * @param {string} order.departureCity - The departure city of the recipient.
   * @param {string} order.departureAddress - The departure address of the recipient.
   * @param {string} order.weight - The weight of the order.
   * @param {number} order.deliveryCost - The delivery cost of the order.
   * @param {boolean} order.paymentByRecipient - Payment is by the recipient.
   * @param {number} order.estimatedCost - The estimated cost of the order.
   * @param {boolean} order.cod - The COD of the order.
   * @param {boolean} order.codByRecipient - COD commission by the recipient.
   * @param {number} order.addServicesCost - The additional services cost of the order.
   * @param {string} order.registerNumber - The register number of the order.
   * @param {string} order.cityName - The city name.
   * @param {boolean} order.isCodByRecipient - use when COD is true and codByRecipient is true.
   * @param {string} order.customTag - Custom tag of the order.
   * @param {boolean} order.isArchived - The flag shows if of order in archived or not.
   * @param {DimensionsMapping} order.dimensions The order dimensions
   * @param {boolean} order.withPlaces -The identification of additional places.
   * @param {OrderPlace[]} order.places - The array of parcels with additional places.
   */
  constructor({
    id,
    trackingCode,
    internationalTrackingNumber,
    description,
    shipmentStatus,
    orderDate,
    deliveryDate,
    recipientName,
    recipientPhone,
    deliveryCity,
    deliveryAddress,
    departureCity,
    departureAddress,
    weight,
    deliveryCost,
    paymentByRecipient,
    estimatedCost,
    cod,
    codByRecipient,
    addServicesCost,
    registerNumber,
    cityName,
    isCodByRecipient,
    customTag,
    dimensions,
    isArchived,
    withPlaces,
    places,
  }) {
    this.id = id;
    this.trackingCode = trackingCode;
    this.internationalTrackingNumber = internationalTrackingNumber;
    this.description = description;
    this.shipmentStatus = shipmentStatus;
    this.orderDate = orderDate;
    this.deliveryDate = deliveryDate;
    this.recipientName = recipientName;
    this.recipientPhone = recipientPhone;
    this.deliveryCity = deliveryCity;
    this.deliveryAddress = deliveryAddress;
    this.departureCity = departureCity;
    this.departureAddress = departureAddress;
    this.weight = weight;
    this.deliveryCost = deliveryCost;
    this.paymentByRecipient = paymentByRecipient;
    this.estimatedCost = estimatedCost;
    this.codByRecipient = codByRecipient;
    this.addServicesCost = addServicesCost;
    this.registerNumber = registerNumber;
    this.cityName = cityName;
    this.customTag = customTag;
    this.dimensions = dimensions;
    this.cod = cod;
    this.isCodByRecipient = isCodByRecipient;
    this.isArchived = isArchived;
    this.withPlaces = withPlaces;
    this.places = places;
  }
}

/**
 * @typedef {object} RecipientInfoMapping Recipient object mapping
 * @property {string} gov_number the government-provided ID of the recipient company
 * @property {string} organization_name the name of the recipient company
 * @property {string} phone the phone number of the recipient
 * @property {string} first_name the name of the recipient
 * @property {string} last_name the surname the recipient
 * @property {string} address - The recipient's address
 * @property {boolean} is_individual_person - Indicates if the recipient is an individual
 * @property {string} post_office_guid - The recipient's post office GUID
 */
export class RecipientInfo {
  /**
   * Creates an object with all recipient information.
   *  @param {RecipientInfoMapping} recipient the recipient information
   *  @param {string} recipient.gov_number the government-provided ID of the recipient company
   *  @param {string} recipient.organization_name the name of the recipient company
   *  @param {string} recipient.phone the phone number of the recipient
   *  @param {string} recipient.first_name the name of the recipient
   *  @param {string} recipient.last_name the surname the recipient
   *  @param {string} recipient.address - The recipient's address
   *  @param {boolean} recipient.is_individual_person - Indicates if the recipient is an individual
   *  @param {string} recipient.post_office_guid - The recipient's post office GUID
   */
  constructor({
    first_name,
    last_name,
    phone,
    organization_name,
    address,
    is_individual_person,
    post_office_guid,
    gov_number,
  }) {
    this.first_name = first_name;
    this.last_name = last_name;
    this.phone = phone;
    this.gov_number = gov_number;
    this.address = address;
    this.organization_name = organization_name;
    this.is_individual_person = is_individual_person;
    this.post_office_guid = post_office_guid;
  }
}

/**
 * @typedef {object} DimensionsMapping Dimensions object mapping
 *
 * @property {number} length - The length of the parcel
 * @property {number} width - The width of the parcel
 * @property {number} height - The height of the parcel
 */

export class Dimensions {
  /**
   * Creates a dimensions object.
   * @param {DimensionsMapping} dimensions dimensions information
   */
  constructor({ length, width, height }) {
    this.length = length;
    this.width = width;
    this.height = height;
  }
}

/**
 * Maps an array of place objects to an array of OrderPlace instances.
 *
 * @param {Array} places - The array of parcel places to map.
 * @returns {Array<OrderPlace>} An array of mapped OrderPlace instances, or an empty array if no valid places are provided.
 */
const mapPlaces = (places) => {
  return Array.isArray(places) ? places.map((place) => new OrderPlace(place)) : [];
};

/**
 * @typedef {object} OrderMapping Order creation data mapping
 *
 * @property {RecipientInfoMapping} recipient - Recipient information
 * @property {string} description - Parcel description
 * @property {string} client_custom_attribute - Parcel custom tag
 * @property {string} recipient_organizations_representative - Recipient organizations representative
 * @property {number} destination_city_id - ID of the destination city
 * @property {boolean} sending_documents - Indicates if sending documents
 * @property {boolean} payment_by_recipient - Indicates payment by recipient
 * @property {number} estimated_cost - Estimated cost
 * @property {number} delivery_cost - Delivery cost
 * @property {boolean} fragile - Indicates if the parcel is fragile
 * @property {boolean} insured - Indicates if the parcel is insured
 * @property {number} insurance_cost - Insurance cost
 * @property {boolean} cod - Indicates Cash on Delivery (COD)
 * @property {number} cod_commission_amount - COD commission amount
 * @property {DimensionsMapping} dimensions - Parcel dimensions
 * @property {string} weight - Parcel weight
 * @property {string} international_tracking_number - The international tracking number.
 * @property {boolean} with_places -The identification of additional places.
 * @property {OrderPlace[]} places - The array of parcels with additional places.
 */

export class OrderRequest {
  /**
   * Updates an order object.
   *
   * @param {OrderMapping} updatedOrder - Order data.
   * @param {RecipientInfoMapping} updatedOrder.recipient - Recipient information
   * @param {string} updatedOrder.description - Parcel description
   * @param {string} updatedOrder.client_custom_attribute - Parcel custom tag
   * @param {string} updatedOrder.recipient_organizations_representative - Recipient organizations representative
   * @param {number} updatedOrder.destination_city_id - ID of the destination city
   * @param {boolean} updatedOrder.sending_documents - Indicates if sending documents
   * @param {boolean} updatedOrder.payment_by_recipient - Indicates payment by recipient
   * @param {number} updatedOrder.estimated_cost - Estimated cost
   * @param {number} updatedOrder.delivery_cost - Delivery cost
   * @param {boolean} updatedOrder.fragile - Indicates if the parcel is fragile
   * @param {boolean} updatedOrder.insured - Indicates if the parcel is insured
   * @param {number} updatedOrder.insurance_cost - Insurance cost
   * @param {boolean} updatedOrder.cod - Indicates Cash on Delivery (COD)
   * @param {boolean} updatedOrder.cod_by_recipient - Indicates Cash on Delivery commission payment by recipient
   * @param {number} updatedOrder.cod_commission_amount - COD commission amount
   * @param {DimensionsMapping} updatedOrder.dimensions - Parcel dimensions
   * @param {string} updatedOrder.weight - Parcel weight
   * @param {string} updatedOrder.international_tracking_number - The international tracking number.
   * @param {boolean} updatedOrder.with_places - The identification of additional places.
   * @param {OrderPlace[]} updatedOrder.places - The array of parcels with additional places.
   */
  constructor({
    recipient,
    description,
    client_custom_attribute,
    destination_city_id,
    sending_documents,
    payment_by_recipient,
    recipient_organizations_representative,
    estimated_cost,
    delivery_cost,
    fragile,
    insured,
    insurance_cost,
    cod,
    cod_commission_amount,
    dimensions,
    weight,
    cod_by_recipient,
    international_tracking_number,
    with_places,
    places,
  }) {
    this.recipient = new RecipientInfo(recipient);
    this.description = description;
    this.client_custom_attribute = client_custom_attribute;
    this.destination_city_id = destination_city_id;
    this.sending_documents = sending_documents;
    this.payment_by_recipient = payment_by_recipient;
    this.recipient_organizations_representative = recipient_organizations_representative;
    this.estimated_cost = estimated_cost;
    this.delivery_cost = delivery_cost;
    this.fragile = fragile;
    this.insured = insured;
    this.insurance_cost = insurance_cost;
    this.cod = cod;
    this.cod_commission_amount = cod_commission_amount;
    this.dimensions = new Dimensions(dimensions);
    this.weight = weight;
    this.cod_by_recipient = cod_by_recipient;
    this.international_tracking_number = international_tracking_number;
    this.with_places = with_places;
    this.places = mapPlaces(places);
  }
}

/**
 * @typedef {object} OrderCreationMapping Order creation data mapping
 *
 * @property {RecipientInfoMapping} recipient - Recipient information
 * @property {string} description - Parcel description
 * @property {string} client_custom_attribute - Parcel custom tag
 * @property {string} recipient_organizations_representative - Recipient organizations representative
 * @property {number} departure_city_id - ID of the departure city
 * @property {number} destination_city_id - ID of the destination city
 * @property {string} post_office_sender_guid - Sender's post office GUID
 * @property {string} sender_address - Sender's address
 * @property {boolean} sending_documents - Indicates if sending documents
 * @property {boolean} payment_by_recipient - Indicates payment by recipient
 * @property {number} estimated_cost - Estimated cost
 * @property {number} delivery_cost - Delivery cost
 * @property {boolean} fragile - Indicates if the parcel is fragile
 * @property {boolean} insured - Indicates if the parcel is insured
 * @property {number} insurance_cost - Insurance cost
 * @property {boolean} cod - Indicates Cash on Delivery (COD)
 * @property {number} cod_commission_amount - COD commission amount
 * @property {DimensionsMapping} dimensions - Parcel dimensions
 * @property {string} weight - Parcel weight
 * @property {string} international_tracking_number - The international tracking number.
 * @property {boolean} with_places -The identification of additional places.
 * @property {OrderPlace[]} places - The array of parcels with additional places.
 */
export class CreateOrderRequest extends OrderRequest {
  /**
   * Creates a new order creation object.
   *
   * @param {OrderCreationMapping} newOrder - New order creation data.
   * @param {RecipientInfoMapping} newOrder.recipient - Recipient information
   * @param {string} newOrder.description - Parcel description
   * @param {string} newOrder.client_custom_attribute - Parcel custom tag
   * @param {string} newOrder.recipient_organizations_representative - Recipient organizations representative
   * @param {number} newOrder.departure_city_id - ID of the departure city
   * @param {number} newOrder.destination_city_id - ID of the destination city
   * @param {string} newOrder.post_office_sender_guid - Sender's post office GUID
   * @param {string} newOrder.sender_address - Sender's address
   * @param {boolean} newOrder.sending_documents - Indicates if sending documents
   * @param {boolean} newOrder.payment_by_recipient - Indicates payment by recipient
   * @param {number} newOrder.estimated_cost - Estimated cost
   * @param {number} newOrder.delivery_cost - Delivery cost
   * @param {boolean} newOrder.fragile - Indicates if the parcel is fragile
   * @param {boolean} newOrder.insured - Indicates if the parcel is insured
   * @param {number} newOrder.insurance_cost - Insurance cost
   * @param {boolean} newOrder.cod - Indicates Cash on Delivery (COD)
   * @param {boolean} newOrder.cod_by_recipient - Indicates Cash on Delivery commission payment by recipient
   * @param {number} newOrder.cod_commission_amount - COD commission amount
   * @param {DimensionsMapping} newOrder.dimensions - Parcel dimensions
   * @param {string} newOrder.weight - Parcel weight
   * @param {string} newOrder.international_tracking_number - The international tracking number.
   * @param {boolean} newOrder.with_places - The identification of additional places.
   * @param {OrderPlace[]} newOrder.places - The array of parcels with additional places.
   */
  constructor({
    recipient,
    description,
    client_custom_attribute,
    departure_city_id,
    destination_city_id,
    post_office_sender_guid,
    sender_address,
    sending_documents,
    payment_by_recipient,
    recipient_organizations_representative,
    estimated_cost,
    delivery_cost,
    insured,
    fragile,
    insurance_cost,
    cod,
    cod_commission_amount,
    dimensions,
    weight,
    cod_by_recipient,
    international_tracking_number,
    with_places,
    places,
  }) {
    super({
      recipient,
      description,
      client_custom_attribute,
      destination_city_id,
      sending_documents,
      payment_by_recipient,
      recipient_organizations_representative,
      estimated_cost,
      delivery_cost,
      insured,
      fragile,
      insurance_cost,
      cod,
      cod_commission_amount,
      dimensions,
      weight,
      cod_by_recipient,
      international_tracking_number,
      with_places,
      places,
    });
    this.departure_city_id = departure_city_id;
    this.post_office_sender_guid = post_office_sender_guid;
    this.sender_address = sender_address;
    this.places = mapPlaces(places);
  }
}

export class OrderTracking {
  /**
   * Creates a new order tracking info from the order creation response.
   * @param {object} orderCreationResponse
   * @param {string} orderCreationResponse.tracking_code the order tracking code
   */
  constructor({ tracking_code }) {
    this.trackingCode = tracking_code;
  }
}

/**
 * @typedef {object} OrderCostsMapping CostsAmount object mapping
 *
 * @property {number} insurance_cost - The insurance cost of the parcel
 * @property {number} cod_commission_amount - The cod commission amount of the parcel
 * @property {number} delivery_cost - The delivery cost of the parcel
 * @property {number} total_amount - The total amount of the parcel
 */

export class OrderCosts {
  /**
   * Creates an amount costs object.
   * @param {OrderCostsMapping} costsAmaunt information about amount costs
   */
  constructor({ insurance_cost, cod_commission_amount, delivery_cost, estimated_cost }) {
    this.insuranceCost = insurance_cost;
    this.codCommissionAmount = cod_commission_amount;
    this.deliveryCost = delivery_cost;
    this.estimatedCost = estimated_cost;
  }
}

/**
 * @typedef {object} CalculateOrderCostsMapping Getting amount costs data mapping
 *
 * @property {string} departure_city_id - ID of the departure city
 * @property {string} destination_city_id - ID of the destination city
 * @property {boolean} courier_pickup - Courier pickup
 * @property {boolean} courier_deliver - Courier deliver
 * @property {boolean} sending_documents - Indicates if sending documents
 * @property {boolean} insured - Indicates if the parcel is insured
 * @property {boolean} cod - Indicates Cash on Delivery (COD)
 * @property {number} estimated_cost - Estimated cost
 * @property {number} length - The length of the parcel
 * @property {number} width - The width of the parcel
 * @property {number} height - The height of the parcel
 * @property {number} weight - Parcel weight
 * @property {boolean} with_places -The identification of additional places.
 * @property {Array} places - The array of parcels with additional places.
 */

export class CalculateOrderCosts {
  /**
   * @param {CalculateOrderCostsMapping} costs Getting amount costs data mapping
   * @param {string} costs.departure_city_id - ID of the departure city
   * @param {string} costs.destination_city_id - ID of the destination city
   * @param {boolean} costs.courier_pickup - Courier pickup
   * @param {boolean} costs.courier_deliver - Courier deliver
   * @param {boolean} costs.sending_documents - Indicates if sending documents
   * @param {boolean} costs.insured - Indicates if the parcel is insured
   * @param {boolean} costs.cod - Indicates Cash on Delivery (COD)
   * @param {number} costs.estimated_cost - Estimated cost
   * @param {number} costs.length - The length of the parcel
   * @param {number} costs.width - The width of the parcel
   * @param {number} costs.height - The height of the parcel
   * @param {number} costs.weight - Parcel weight
   * @param {boolean} costs.with_places -The identification of additional places.
   * @param {OrderPlace[]} costs.places - The array of places.
   *
   */
  constructor({
    departure_city_id,
    length,
    width,
    height,
    weight,
    estimated_cost,
    cod,
    insured,
    sending_documents,
    courier_deliver,
    destination_city_id,
    courier_pickup,
    with_places,
    places,
  }) {
    this.departure_city_id = departure_city_id;
    this.destination_city_id = destination_city_id;
    this.courier_pickup = courier_pickup;
    this.courier_deliver = courier_deliver;
    this.sending_documents = sending_documents;
    this.insured = insured;
    this.cod = cod;
    this.estimated_cost = estimated_cost;
    this.length = length;
    this.width = width;
    this.height = height;
    this.weight = weight;
    this.with_places = with_places;
    this.places = places;
  }
}

/**
 * Converts a Date object to an ISO string or returns null if the input is null or undefined.
 *
 * @param {Date} date - The date to convert.
 * @returns {string|null} - The ISO string representation of the date or null.
 * @example
 * const date = new Date(); // Wed Dec 27 2023 00:00:00 GMT+0200
 * const isoString = toIsoStringOrNull(date); // Returns: 2023-12-26T22:00:00.000Z
 */
function toIsoStringOrNull(date) {
  return date ? date.toISOString() : null;
}

/**
 * Represents query parameters for an orders list.
 */
export class OrdersListQueryParams {
  /**
   * Creates a new object with query parameters for an orders list.
   *
   * @param {Date} [date_from] start date of the filtering interval
   * @param {Date} [date_to] end date of the filtering interval
   */
  constructor({ date_from = null, date_to = null }) {
    this.date_from = toIsoStringOrNull(date_from);
    this.date_to = toIsoStringOrNull(date_to);
  }
}
