export const DELIVERY_METHOD = {
  DEPARTMENT: "department",
  COURIER: "courier",
};
export const RECIPIENT_TYPE = {
  COMPANY: "legal entity",
  INDIVIDUAL: "individual person",
};

export const PARCEL_TYPE = {
  PARCEL: "parcel",
  PARCEL_WITH_ADDITIONAL_PALECES: "parcel with additional places",
  DOCUMENTS: "documents",
};

export const DELIVERY_OPTIONS = {
  FRAGILE: "fragile",
  INSURANCE: "insurance",
};

export const PAYMENT_TYPE = {
  SENDER: "paySender",
  RECIPIENT: "payRecipient",
};

export const ORDER_STATUS = {
  DRAFT: "Draft",
  UNDELIVERED: "Undelivered",
  ISSUED_TO_RECIPIENT: "Issued to the recipient",
  ISSUED_TO_COURIER: "Issued to the courier",
  ACCEPTED_FOR_SHIPMENT: "Accepted for shipment",
  PREPARED_FOR_SHIPMENT: "Prepared for shipment",
  PREPARED_FOR_THE_COURIER: "Prepared for the courier",
  SENT: "Sent",
  DELIVERED_TO_THE_ISSUING_OFFICE: "Delivered to the issuing office",
  DELIVERED_TO_SORTING_WAREHOUSE: "Delivered to sorting warehouse",
  READY_TO_ISSUE: "Ready to issue",
  COURIER_PICKED_UP_FROM_THE_SENDER: "Courier picked up from the sender",
  RETURNED_TO_SENDER: "Returned to sender",
  RETURN_TO_SENDER: "Return to sender",
  IMPORT_CUSTOMS_HANDOVER: "Import customs handover",
};

export const REGISTER_STATUS_FROM_API = {
  DRAFT: "draft",
  READY_FOR_PICKUP: "ready_for_pickup",
  READY_FOR_SHIPMENT: "ready_for_shipment",
  ACCEPTED_IN_POST_OFFICE: "accepted_in_post_office",
  PICKED_UP_BY_COURIER: "picked_up_by_courier",
  CANCELED: "canceled",
};

/**
 * Fields extracted from the API response with their corresponding names of columns.
 * @typedef {Object} FIELDS_NAME_FROM_API
 * @property {string} RECIPIENT_GOV_NUMBER - Government identification number of the recipient.
 * @property {string} RECIPIENT_NAME - Name of the recipient for individual recipient type.
 * @property {string} RECIPIENT_ORGANIZATION_NAME - Name of the recipient's organization for legal entity recipient type.
 * @property {string} RECIPIENT_PHONE - Phone number of the recipient.
 * @property {string} RECIPIENT_ADDRESS - Address of the recipient.
 * @property {string} RECIPIENT_POST_OFFICE - GUID of the recipient's post office.
 * @property {string} WEIGHT - Weight of the order.
 * @property {string} DIMENSIONS_LENGTH - Length dimension of the order.
 * @property {string} DIMENSIONS_WIDTH - Width dimension of the order.
 * @property {string} DIMENSIONS_HEIGHT - Height dimension of the order.
 * @property {string} DEPARTURE_CITY_ID - ID of the departure city.
 * @property {string} DESTINATION_CITY_ID - ID of the destination city.
 * @property {string} PAYMENT_BY_RECIPIENT - Payment method chosen by the recipient.
 * @property {string} COD_BY_RECIPIENT - Cash on delivery method chosen by the recipient.
 * @property {string} COD - Cash on delivery amount.
 * @property {string} ESTIMATED_COST - Estimated cost of the order.
 * @property {string} DELIVERY_COST - Delivery cost of the order.
 * @property {string} INSURANCE_COST - Insurance cost of the order.
 * @property {string} FRAGILE - Indicates if the order is fragile.
 * @property {string} INSURED - Indicates if the order is insured.
 * @property {string} INTERNATIONAL_TRACKING_NUMBER - International tracking number of the order.
 * @property {string} RECIPIENT_NON_FIELD_ERRORS - Missed some required fields.
 */
export const FIELDS_NAME_FROM_API = {
  RECIPIENT_GOV_NUMBER: "recipient_gov_number",
  RECIPIENT_NAME: "recipient_name",
  RECIPIENT_FIRST_NAME: "recipient_first_name",
  RECIPIENT_LAST_NAME: "recipient_last_name",
  RECIPIENT_ORGANIZATION_NAME: "recipient_organization_name",
  RECIPIENT_PHONE: "recipient_phone",
  RECIPIENT_ADDRESS: "recipient_address",
  RECIPIENT_POST_OFFICE: "post_office_guid",
  WEIGHT: "weight",
  DIMENSIONS_LENGTH: "dimensions_length",
  DIMENSIONS_WIDTH: "dimensions_width",
  DIMENSIONS_HEIGHT: "dimensions_height",
  DEPARTURE_CITY_ID: "departure_city_id",
  DESTINATION_CITY_ID: "destination_city_id",
  PAYMENT_BY_RECIPIENT: "payment_by_recipient",
  COD_BY_RECIPIENT: "cod_by_recipient",
  COD: "cod",
  ESTIMATED_COST: "estimated_cost",
  DELIVERY_COST: "delivery_cost",
  INSURANCE_COST: "insurance_cost",
  FRAGILE: "fragile",
  INSURED: "insured",
  INTERNATIONAL_TRACKING_NUMBER: "international_tracking_number",
  RECIPIENT_NON_FIELD_ERRORS: "recipient_non_field_errors",
};

/**
 * Representing different types of errors  from backend encountered in the application.
 * @typedef {Object} ERROR_TYPES
 * @property {string} FAILED_ON_PARSING_OR_VALIDATION - Error occurred during parsing or validation.
 * @property {string} SENDER_DATA_MISSMATCH - Sender data mismatch error.
 * @property {string} TOO_MUCH_ORDERS_IN_FILE - Too much orders in file error.
 */
export const ERROR_TYPES = {
  FAILED_ON_PARSING_OR_VALIDATION: "FAILED_ON_PARSING_OR_VALIDATION",
  SENDER_DATA_MISSMATCH: "sender_data_missmatch",
  TOO_MUCH_ORDERS_IN_FILE: "too_much_orders_in_file",
};
