import React from "react";
import { Field, useFormikContext } from "formik";
import { Box, Checkbox, Divider, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import RadioGroupField from "../radioGroupField/RadioGroupField";
import { PARCEL_TYPE } from "../constants";
import {
  adaptiveBoxWrapper,
  displayAdaptiveBoxStyles,
  headerSectionStyle,
  infoSectionStyle,
  leftFieldStyle,
  radioGroupBtnCustomStyle,
  rightFieldStyle,
} from "./tailwindClasses";

const ParcelInformation = ({ onParcelTypeChange }) => {
  const { t } = useTranslation();
  const {
    values: {
      parcelType,
      parcelWeight,
      parcelLength,
      parcelWidth,
      parcelHeight,
      estimatedValue,
      parcelDescription,
      parcelClientCustomAttribute,
      insured,
      fragile,
    },
    errors,
    touched,
    handleChange,
    setFieldValue,
  } = useFormikContext();

  const parcelTypeLabels = [
    { value: PARCEL_TYPE.PARCEL, label: t("orderCreation.parcelPartRadioGroupTypeParcel") },
    {
      value: PARCEL_TYPE.PARCEL_WITH_ADDITIONAL_PALECES,
      label: t("orderCreation.parcelRadioGroupTypeParcelWithAdditionalPlaces"),
    },
    { value: PARCEL_TYPE.DOCUMENTS, label: t("orderCreation.parcelPartRadioGroupTypeDocuments") },
  ];

  const isDocumentsParcel = parcelType === PARCEL_TYPE.DOCUMENTS;
  const handleInvalidInput = (event) => {
    const inputValue = event.target.value;

    if (inputValue.includes(",")) {
      event.target.setCustomValidity(t("errorsMessages.useDot"));
      return;
    }

    const decimalIndex = inputValue.indexOf(".");
    if (decimalIndex !== -1 && inputValue.substring(decimalIndex + 1).length > 2) {
      event.target.setCustomValidity(t("errorsMessages.max2Digits"));
      return;
    }
    event.target.setCustomValidity("");
  };

  const baseLabelStyle = "w-full xl:w-1/3 xl:mr-6 md:pt-5";
  const labelFragileStyle = `${baseLabelStyle} mr-6 ${insured || isDocumentsParcel ? "text-gray-400" : ""}`;
  const labelInsuredStyle = `${baseLabelStyle} ${fragile || isDocumentsParcel ? "text-gray-400" : ""}`;

  const handleParcelTypeChange = (event) => {
    const selectedValue = event.target.value;
    const isAdditionalPlacesParcel = selectedValue === PARCEL_TYPE.PARCEL_WITH_ADDITIONAL_PALECES;

    onParcelTypeChange(isAdditionalPlacesParcel);
    handleChange(event);
    setFieldValue("withPlaces", isAdditionalPlacesParcel);
  };
  const isAdditionalPlacesParcel = parcelType === PARCEL_TYPE.PARCEL_WITH_ADDITIONAL_PALECES;

  return (
    <section className={infoSectionStyle}>
      <Typography variant={"h6"} className={headerSectionStyle}>
        {t("orderCreation.stepInfoParcel")}
      </Typography>
      <Divider orientation={"horizontal"} flexItem />
      <RadioGroupField
        name="parcelType"
        value={parcelType}
        onChange={handleParcelTypeChange}
        labels={parcelTypeLabels}
        className={radioGroupBtnCustomStyle}
      />
      {isAdditionalPlacesParcel ? (
        ""
      ) : (
        <>
          <Box
            className={
              "flex flex-col md:flex-row items-center justify-start w-5/6 pb-0 md:pb-1.5 p-1.5 md:mb-5 mx-6"
            }
          >
            <Box
              className={`flex ${displayAdaptiveBoxStyles} md:items-center md:justify-between w-full`}
            >
              <Field
                as={TextField}
                label={t("orderCreation.parcelLength")}
                variant="standard"
                className={leftFieldStyle}
                name="parcelLength"
                value={parcelLength}
                placeholder={t("placeholders.placeholderLength")}
                disabled={isDocumentsParcel}
                error={touched.parcelLength && Boolean(errors.parcelLength)}
                helperText={touched.parcelLength && errors.parcelLength}
                inputProps={{ maxLength: 4 }}
              />
              <Field
                as={TextField}
                label={t("orderCreation.parcelWidth")}
                name="parcelWidth"
                value={parcelWidth}
                placeholder={t("placeholders.placeholderWidth")}
                variant="standard"
                className={`${rightFieldStyle} mb-0`}
                error={touched.parcelWidth && Boolean(errors.parcelWidth)}
                helperText={touched.parcelWidth && errors.parcelWidth}
                disabled={isDocumentsParcel}
                inputProps={{ maxLength: 4 }}
              />
              <Box className={"xl:w-1/3"} />
            </Box>
          </Box>
          <Box
            className={
              "flex flex-col md:flex-row items-center justify-start w-5/6 pt-0 md:pt-1.5 p-1.5 md:mb-5 mx-6"
            }
          >
            <Box
              className={"flex flex-col md:flex-row md:items-center md:justify-between w-full"}
            >
              <Field
                as={TextField}
                label={t("orderCreation.parcelHeight")}
                name="parcelHeight"
                variant="standard"
                value={parcelHeight}
                error={touched.parcelHeight && Boolean(errors.parcelHeight)}
                helperText={touched.parcelHeight && errors.parcelHeight}
                className={leftFieldStyle}
                placeholder={t("placeholders.placeholderHeight")}
                disabled={isDocumentsParcel}
                inputProps={{ maxLength: 4 }}
              />
              <Field
                as={TextField}
                label={t("orderCreation.parcelWeight")}
                name="parcelWeight"
                value={parcelWeight}
                error={touched.parcelWeight && Boolean(errors.parcelWeight)}
                helperText={touched.parcelWeight && errors.parcelWeight}
                variant="standard"
                inputProps={{
                  min: 0.1,
                  max: 100,
                  pattern: "[0-9]+([\\.][0-9]{0,2})?",
                  onInvalid: handleInvalidInput,
                  onInput: (event) => {
                    event.target.setCustomValidity("");
                  },
                }}
                className={rightFieldStyle}
                placeholder={t("placeholders.placeholderWeight")}
                disabled={isDocumentsParcel || isAdditionalPlacesParcel}
              />
              <Box className={"xl:w-1/3"} />
            </Box>
          </Box>
        </>
      )}
      <Box className={"w-5/6 flex items-center flex-wrap justify-start px-1.5 mb-2 md:mb-5"}>
        <Box className={"flex flex-col md:flex-row md:items-center md:justify-between w-full"}>
          <Box className={"w-full mx-3 xl:w-1/3"}>
            <label className={`${baseLabelStyle} pl-0 md:mb:0`}>
              <Field type="checkbox" name="cod" as={Checkbox} />
              {t("orderCreation.parcelCod")}
            </label>
          </Box>
          <Field
            as={TextField}
            label={t("orderCreation.parcelEstimatedValue")}
            name="estimatedValue"
            value={estimatedValue}
            error={touched.estimatedValue && Boolean(errors.estimatedValue)}
            helperText={touched.estimatedValue && errors.estimatedValue}
            placeholder={`0 ${t("orderTracking.gel")}`}
            variant="standard"
            className={`w-full xl:w-1/3 mx-6 md:ml-12`}
            inputProps={{
              pattern: "[0-9]+([\\.][0-9]{0,2})?",
              onInvalid: handleInvalidInput,
              onInput: (event) => {
                event.target.setCustomValidity("");
              },
            }}
          />
          <Box className={"hidden xl:flex xl:w-1/3"} />
        </Box>
      </Box>
      <Box className={"w-5/6 flex items-center flex-wrap justify-start px-1.5 md:mb-5"}>
        <Box className={"flex flex-col md:flex-row md:items-center w-full mx-3"}>
          <label className={labelFragileStyle}>
            <Field
              type="checkbox"
              name="fragile"
              disabled={insured || isDocumentsParcel}
              as={Checkbox}
            />
            {t("orderCreation.parcelPartRadioGroupDeliveryOptionsFragile")}
          </label>
          <label className={labelInsuredStyle}>
            <Field
              type="checkbox"
              name="insured"
              disabled={fragile || isDocumentsParcel}
              as={Checkbox}
            />
            {t("orderCreation.parcelPartRadioGroupDeliveryOptionsInsurance")}
          </label>
        </Box>
      </Box>
      <Box className={adaptiveBoxWrapper}>
        <Field
          as={TextField}
          label={t("orderCreation.parcelAttribute")}
          name="parcelClientCustomAttribute"
          value={parcelClientCustomAttribute}
          error={
            touched.parcelClientCustomAttribute && Boolean(errors.parcelClientCustomAttribute)
          }
          helperText={touched.parcelClientCustomAttribute && errors.parcelClientCustomAttribute}
          type="text"
          variant="standard"
          className={leftFieldStyle}
          placeholder={t("placeholders.customTag")}
          inputProps={{ maxLength: 100 }}
        />
        <Field
          as={TextField}
          label={t("orderCreation.parcelDescription")}
          name="parcelDescription"
          value={parcelDescription}
          error={touched.parcelDescription && Boolean(errors.parcelDescription)}
          helperText={touched.parcelDescription && errors.parcelDescription}
          type="text"
          variant="standard"
          className={"w-full md:w-2/3 md:mr-10"}
          placeholder={t("placeholders.description")}
          inputProps={{ maxLength: 255 }}
        />
      </Box>
    </section>
  );
};

ParcelInformation.label = "orderCreation.stepInfoParcel";

ParcelInformation.validationSchema = (t) =>
  Yup.lazy(() => {
    const isPositiveInteger = (value) => {
      const numberValue = Number.parseInt(value);
      return Number.isInteger(numberValue) && numberValue > 0;
    };
    return Yup.object().shape(
      {
        parcelLength: Yup.string().when(["parcelType", "parcelWidth", "parcelHeight"], {
          is: (parcelType, parcelWidth, parcelHeight) => {
            return parcelType === PARCEL_TYPE.PARCEL && (parcelHeight > 0 || parcelWidth > 0);
          },
          then: (schema) =>
            schema
              .matches(/^[0-9]{1,4}$/, t("errorsMessages.onlyDigitsError"))
              .required(t("errorsMessages.lengthRequired"))
              .test(
                "positiveIntegers",
                t("errorsMessages.positiveIntegerRequired"),
                isPositiveInteger,
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        parcelWidth: Yup.string().when(["parcelType", "parcelHeight", "parcelLength"], {
          is: (parcelType, parcelHeight, parcelLength) => {
            return parcelType === PARCEL_TYPE.PARCEL && (parcelLength > 0 || parcelHeight > 0);
          },
          then: (schema) =>
            schema
              .matches(/^[0-9]{1,4}$/, t("errorsMessages.onlyDigitsError"))
              .required(t("errorsMessages.widthRequired"))
              .test(
                "positiveIntegers",
                t("errorsMessages.positiveIntegerRequired"),
                isPositiveInteger,
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        parcelHeight: Yup.string().when(["parcelType", "parcelWidth", "parcelLength"], {
          is: (parcelType, parcelWidth, parcelLength) => {
            return parcelType === PARCEL_TYPE.PARCEL && (parcelWidth > 0 || parcelLength > 0);
          },
          then: (schema) =>
            schema
              .matches(/^[0-9]{1,4}$/, t("errorsMessages.onlyDigitsError"))
              .required(t("errorsMessages.heightRequired"))
              .test(
                "positiveIntegers",
                t("errorsMessages.positiveIntegerRequired"),
                isPositiveInteger,
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        parcelWeight: Yup.number().when("parcelType", {
          is: (parcelType) => parcelType === PARCEL_TYPE.PARCEL,
          then: (schema) =>
            schema
              .required(t("errorsMessages.weightRequired"))
              .typeError(t("errorsMessages.useDot"))
              .min(0.1, t("errorsMessages.minValWeight"))
              .max(100, t("errorsMessages.maxValWeight"))
              .test("maxDigitsAfterDecimal", t("errorsMessages.max2Digits"), (value) =>
                /^\d+(\.\d{1,2})?$/.test(value),
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        estimatedValue: Yup.number().when(["cod", "insured"], {
          is: (cod, insured) => cod === true || insured === true,
          then: (schema) =>
            schema
              .required(t("errorsMessages.estimatedCostRequired"))
              .typeError(t("errorsMessages.useDot"))
              .min(0.01, t("errorsMessages.minEstimatedCost"))
              .max(10000, t("errorsMessages.maxEstimationCost"))
              .test("maxDigitsAfterDecimal", t("errorsMessages.max2Digits"), (number) =>
                /^\d+(\.\d{1,2})?$/.test(number),
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        parcelDescription: Yup.string().max(255, t("errorsMessages.maxDescriptionLength")),
        parcelClientCustomAttribute: Yup.string().max(
          100,
          t("errorsMessages.maxCustomTagLength"),
        ),
      },
      [
        ["parcelWidth", "parcelLength"],
        ["parcelHeight", "parcelLength"],
        ["parcelHeight", "parcelWidth"],
      ],
    );
  });

export default ParcelInformation;
