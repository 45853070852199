import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import OrderPlaceItem from "./parcelInfo/OrderPlaceItem";
import OrderPlaceItemList from "./parcelInfo/OrderPlaceItemList";

const ParcelWithAdditionalInformation = ({ isUpdating }) => {
  const { t } = useTranslation();
  const {
    values: { places },
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();

  const [parcels, setParcels] = useState(places || []);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editTrackingCode, setEditTrackingCode] = useState(null);

  const handleAddParcel = async (parcelData) => {
    setParcels((prevParcels) => {
      let updatedParcels = [...prevParcels];

      if (editTrackingCode) {
        const index = updatedParcels.findIndex((p) => p.tracking_code === editTrackingCode);
        if (index !== -1) {
          updatedParcels[index] = { ...updatedParcels[index], ...parcelData };
        }
      } else if (editIndex !== null) {
        if (updatedParcels[editIndex]) {
          updatedParcels[editIndex] = { ...updatedParcels[editIndex], ...parcelData };
        }
      } else {
        updatedParcels = [...updatedParcels, parcelData];
      }

      setFieldValue("places", updatedParcels);
      return updatedParcels;
    });

    setIsEditing(false);
    setIsAdding(false);
    setEditIndex(null);
    setEditTrackingCode(null);
  };

  const handleRemoveParcel = (index) => {
    const updatedPlaces = places.filter((_, i) => i !== index);
    setParcels(updatedPlaces);
    setFieldValue("places", updatedPlaces);
  };

  const handleEditParcel = (identifier) => {
    if (typeof identifier === "number") {
      setEditIndex(identifier);
      setEditTrackingCode(null);
    } else {
      setEditTrackingCode(identifier);
      setEditIndex(null);
    }

    setIsEditing(true);
  };

  useEffect(() => {
    if (isEditing && (editIndex !== null || editTrackingCode !== null)) {
      const selectedParcel = editTrackingCode
        ? parcels.find((parcel) => parcel.tracking_code === editTrackingCode)
        : parcels[editIndex] || null;

      if (selectedParcel) {
        setFieldValue("tracking_code", selectedParcel.tracking_code || "");
        setFieldValue("description", selectedParcel.description || "");
        setFieldValue("length", selectedParcel.length || "");
        setFieldValue("width", selectedParcel.width || "");
        setFieldValue("height", selectedParcel.height || "");
        setFieldValue("weight", selectedParcel.weight || "");
      }
    }
  }, [isEditing, editIndex, editTrackingCode, parcels, setFieldValue]);

  const handleAddItemClick = () => {
    setEditIndex(null);
    setEditTrackingCode(null);
    setIsAdding(true);
    setIsEditing(false);
  };

  return (
    <section className="w-11/12 md:w-10/12 ml-4 md:ml-8 flex flex-col">
      <Typography variant="h6">{t("orderCreation.stepParcelWithAdditionalPlaces")}</Typography>
      <Divider className="my-3" />
      <ButtonComponent
        text={`+ ${t("orderCreation.parcelAddItem")}`}
        className="xl:w-1/5 my-3"
        onClick={handleAddItemClick}
        disabled={parcels.length >= 10}
      />

      {isAdding && (
        <OrderPlaceItem
          onAddParcelItem={handleAddParcel}
          onClose={() => {
            setIsAdding(false);
            setFieldValue("tracking_code", "");
            setFieldValue("description", "");
            setFieldValue("length", "");
            setFieldValue("width", "");
            setFieldValue("height", "");
            setFieldValue("weight", "");
          }}
        />
      )}

      {isEditing ? (
        <OrderPlaceItem
          onAddParcelItem={handleAddParcel}
          isUpdating={isUpdating}
          onUpdateParcelItem={(updatedParcelData) => handleAddParcel(updatedParcelData)}
          existingParcel={
            editTrackingCode
              ? parcels.find((parcel) => parcel.tracking_code === editTrackingCode)
              : parcels[editIndex]
          }
          onClose={() => {
            setIsAdding(false);
            setIsEditing(false);
            setEditIndex(null);
            setEditTrackingCode(null);
            setFieldValue("tracking_code", "");
            setFieldValue("description", "");
            setFieldValue("length", "");
            setFieldValue("width", "");
            setFieldValue("height", "");
            setFieldValue("weight", "");
          }}
        />
      ) : (
        <OrderPlaceItemList
          parcelItems={parcels}
          deleteParcelItem={handleRemoveParcel}
          updateParcelItem={handleEditParcel}
        />
      )}

      {errors.places && touched.places && <Typography color="error">{errors.places}</Typography>}
    </section>
  );
};

ParcelWithAdditionalInformation.label = "orderCreation.stepParcelWithAdditionalPlaces";

ParcelWithAdditionalInformation.validationSchema = (t) =>
  Yup.lazy(() => {
    const isPositiveInteger = (value) => {
      const numberValue = Number.parseInt(value);
      return Number.isInteger(numberValue) && numberValue > 0;
    };

    return Yup.object().shape(
      {
        length: Yup.string().when(["width", "height", "weight"], {
          is: (width, height, weight) => width > 0 || height > 0 || weight > 0,
          then: (schema) =>
            schema
              .matches(/^[0-9]{1,4}$/, t("errorsMessages.onlyDigitsError"))
              .required(t("errorsMessages.lengthRequired"))
              .test(
                "positiveIntegers",
                t("errorsMessages.positiveIntegerRequired"),
                isPositiveInteger,
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        width: Yup.string().when(["length", "height", "weight"], {
          is: (length, height, weight) => length > 0 || height > 0 || weight > 0,
          then: (schema) =>
            schema
              .matches(/^[0-9]{1,4}$/, t("errorsMessages.onlyDigitsError"))
              .required(t("errorsMessages.widthRequired"))
              .test(
                "positiveIntegers",
                t("errorsMessages.positiveIntegerRequired"),
                isPositiveInteger,
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        height: Yup.string().when(["length", "width", "weight"], {
          is: (length, width, weight) => length > 0 || width > 0 || weight > 0,
          then: (schema) =>
            schema
              .matches(/^[0-9]{1,4}$/, t("errorsMessages.onlyDigitsError"))
              .required(t("errorsMessages.heightRequired"))
              .test(
                "positiveIntegers",
                t("errorsMessages.positiveIntegerRequired"),
                isPositiveInteger,
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        weight: Yup.number().when(["length", "width", "height"], {
          is: (length, width, height) => length > 0 || width > 0 || height > 0,
          then: (schema) =>
            schema
              .required(t("errorsMessages.weightRequired"))
              .typeError(t("errorsMessages.useDot"))
              .min(0.1, t("errorsMessages.minValWeight"))
              .max(100, t("errorsMessages.maxValWeight"))
              .test("maxDigitsAfterDecimal", t("errorsMessages.max2Digits"), (value) =>
                /^\d+(\.\d{1,2})?$/.test(value),
              ),
          otherwise: (schema) => schema.notRequired(),
        }),
        description: Yup.string().max(255, t("errorsMessages.maxDescriptionLength")),
      },
      [
        ["length", "width"],
        ["length", "height"],
        ["width", "height"],
        ["length", "weight"],
        ["width", "weight"],
        ["height", "weight"],
      ],
    );
  });

export default ParcelWithAdditionalInformation;
