import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { Box, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../buttonComponent/ButtonComponent";

const OrderPlaceItem = ({
  onAddParcelItem,
  onUpdateParcelItem,
  isUpdating,
  existingParcel,
  onClose,
}) => {
  const { t } = useTranslation();
  const {
    values: { width, weight, height, length, description, tracking_code },
    errors,
    touched,
    isValid,
    dirty,
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (isUpdating && existingParcel) {
      setFieldValue("tracking_code", existingParcel.tracking_code);
      setFieldValue("description", existingParcel.description);
      setFieldValue("length", existingParcel.length);
      setFieldValue("width", existingParcel.width);
      setFieldValue("height", existingParcel.height);
      setFieldValue("weight", existingParcel.weight);
    }
  }, [isUpdating, existingParcel, setFieldValue]);

  const handleFormSubmit = () => {
    const parcelData = {
      tracking_code: tracking_code,
      description: description,
      length: length,
      width: width,
      height: height,
      weight: weight,
    };

    if (isUpdating) {
      onUpdateParcelItem(parcelData);
    } else {
      onAddParcelItem(parcelData);
    }

    setFieldValue("description", "");
    setFieldValue("length", null);
    setFieldValue("width", null);
    setFieldValue("height", null);
    setFieldValue("weight", null);
    onClose();
  };

  return (
    <Box className="flex flex-col w-full">
      <Typography variant="h6">{t("orderCreation.itemTitle")}</Typography>
      <Box className={"flex smMax:flex-col md:flex-row items-center justify-start"}>
        <Field
          as={TextField}
          label={t("orderCreation.parcelDescription")}
          name="description"
          value={description}
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
          type="text"
          variant="standard"
          className={"w-full md:w-2/3 md:mr-1"}
          placeholder={t("placeholders.description")}
          inputProps={{ maxLength: 255 }}
        />
        <Box className={`flex flex-col md:flex-row md:items-center md:justify-between w-full`}>
          <Field
            as={TextField}
            label={t("orderCreation.parcelLength")}
            variant="standard"
            className="w-full md:w-1/3 md:mx-1"
            name="length"
            value={length}
            placeholder={t("placeholders.placeholderLength")}
            error={touched.length && Boolean(errors.length)}
            helperText={touched.length && errors.length}
            inputProps={{ maxLength: 4 }}
          />
          <Field
            as={TextField}
            label={t("orderCreation.parcelWidth")}
            name="width"
            value={width}
            placeholder={t("placeholders.placeholderWidth")}
            variant="standard"
            className="w-full md:w-1/3 md:mx-1"
            error={touched.width && Boolean(errors.width)}
            helperText={touched.width && errors.width}
            inputProps={{ maxLength: 4 }}
          />
          <Field
            as={TextField}
            label={t("orderCreation.parcelHeight")}
            name="height"
            variant="standard"
            value={height}
            error={touched.height && Boolean(errors.height)}
            helperText={touched.height && errors.height}
            className="w-full md:w-1/3 md:mx-1"
            placeholder={t("placeholders.placeholderHeight")}
            inputProps={{ maxLength: 4 }}
          />
          <Field
            as={TextField}
            label={t("orderCreation.parcelWeight")}
            name="weight"
            value={weight}
            error={touched.weight && Boolean(errors.weight)}
            helperText={touched.weight && errors.weight}
            variant="standard"
            inputProps={{
              min: 0.1,
              max: 100,
              pattern: "[0-9]+([\\.][0-9]{0,2})?",
              onInput: (event) => {
                event.target.setCustomValidity("");
              },
            }}
            className="w-full md:w-1/3 md:ml-1"
            placeholder={t("placeholders.placeholderWeight")}
          />
        </Box>
      </Box>
      {errors.places && touched.places && <Typography color="error">{errors.places}</Typography>}
      <Box className="flex justify-end mt-3">
        <ButtonComponent
          text={t("buttonSave")}
          onClick={handleFormSubmit}
          disabled={(!isValid && dirty) || !touched}
        />
        <ButtonComponent text={t("buttonCancel")} variant="text" onClick={onClose} />
      </Box>
    </Box>
  );
};

export default OrderPlaceItem;
